<template>
    <div>
        <div class="set-container">
            <div class="tab-box">
                <el-button @click="seeBtn">预览</el-button>
                <el-button type="primary" @click="addBtn">新增轮播</el-button>
            </div>

            <el-table :data="infoList" class="customTable" style="width: 100%; flex: 1" height="1%" ref="multipleTable">
                <el-table-column prop="sort" label="排序" width="100" align="center"></el-table-column>
<!--                <el-table-column prop="jump_url" label="跳转路径" align="center"></el-table-column>-->
                <el-table-column label="轮播图" align="center">
                    <template slot-scope="scope">
                        <div class="banner-box">
                            <img :src="hostUrl + scope.row.banner_img" alt="">
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" custom-class="custom-dialog"
                       :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
                <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="轮播图上传" prop="banner_img">
                        <el-upload class="img-uploader"
                                   :action="uploadCover"
                                   :headers="headersParams"
                                   name="banner_img"
                                   accept=".jpg, .jpeg, .png"
                                   :show-file-list="false"
                                   :on-success="handleCoverSuccess"
                                   :before-upload="beforeCoverUpload">
                            <img v-if="addForm.banner_img" :src="addForm.banner_img" class="quickTalk-img">
                            <div class="add-box" v-else>
                                <i class="iconfont add-icon">&#xe8c6;</i>
                                <div class="add-text">轮播图</div>
                            </div>
                        </el-upload>
                        <span class="prompt-msg">轮播图尺寸：405*272；支持格式：.jpg, .jpeg, .png</span>
                    </el-form-item>
                    <el-form-item label="轮播图排序" prop="sort">
                        <el-input-number class="input-number-left" v-model="addForm.sort" :controls="false" :min="1" :max="255" style="width: 100%"></el-input-number>
                    </el-form-item>

<!--                    <el-form-item label="轮播图链接" prop="jump_url">-->
<!--                        <el-select filterable v-model="addForm.jump_url" placeholder="请选择" style="width: 100%">-->
<!--                            <el-option :label="item.name" :value="item.id" v-for="item in urlList" :key="item.id"></el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                </el-form>
                <div slot="footer" style="text-align: center">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog title="轮播图预览" :visible.sync="bannerDialog" width="500px" custom-class="custom-dialog"
                       :close-on-click-modal="false">
                <el-carousel class="banner-list" arrow="never">
                    <el-carousel-item v-for="item in infoList" :key="item.banner_id" class="banner-item">
                        <img :src="hostUrl + item.banner_img" alt="">
                    </el-carousel-item>
                </el-carousel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import { bannerBannerList, bannerUploadBannerImg, bannerAddBanner, bannerDelBanner } from '@/config/apis.js'
    export default {
        name: "BannerManage",
        data() {
            return {
                infoList: [],
                urlList: [],

                // 弹窗
                dialogVisible: false,
                addForm: {
                    banner_id: null,
                    banner_img: '',
                    sort: void 0,

                    jump_url: '',
                },
                rules: {
                    banner_img: {required: true, message: '请上传轮播图', trigger: 'change'},
                    sort: {required: true, message: '请输入排序', trigger: 'blur'},
                },
                headersParams: {
                    Authorization: localStorage.getItem('schoolToken')
                },
                uploadCover: bannerUploadBannerImg(),
                dialogTitle: '',
                hostUrl: localStorage.getItem('domain_url'),

                bannerDialog: false,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: 0,
                }
                bannerBannerList(params).then(res => {
                    this.infoList = res.data.list
                }).catch(err => {})
            },
            addBtn() {
                if (this.infoList.length === 5) {
                    return this.$message.warning('轮播图不能超过五张，请删除或编辑')
                }
                this.dialogVisible = true
                this.dialogTitle = '新增轮播'
                this.resetForm()
            },
            seeBtn() {
                this.bannerDialog = true
            },
            editBtn(val) {
                this.addForm = val
                this.dialogVisible = true
                this.dialogTitle = '编辑轮播'
                
            },
            delBtn(val) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let formData = new FormData()
                    formData.append('banner_id', val.banner_id)
                    bannerDelBanner(formData).then(res => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch(err => {})
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },

            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.dialogVisible = false
                // this.resetForm()
            },
            // 弹窗内保存
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('banner_img', this.addForm.banner_img)
                        formData.append('sort', this.addForm.sort)
                        // formData.append('jump_url', this.addForm.jump_url)
                        if (this.addForm.banner_id) {
                            formData.append('banner_id', this.addForm.banner_id)
                        }
                        bannerAddBanner(formData).then(res => {
                            this.$message.success(res.msg)
                            this.getList()
                            this.dialogVisible = false
                        }).catch(err => {})
                    } else {
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm() {
                // this.$refs.addForm.resetFields();
                this.addForm = {
                    // banner_id: null,
                    banner_img: '',
                    // sort: void 0,
                    // jump_url: '',
                }
            },
            // 图片上传前
            beforeCoverUpload(file) {
                // const is10M = file.size / 1024 / 1024 < 10;
                // if (!is10M) {
                //     this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过10MB');
                //     return false;
                // } else {
                const isSize = new Promise((resolve, reject) => {
                    const width = 405;
                    const height = 272;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为405px x 272px，大小不可超过10MB');
                        return Promise.reject();
                    },
                );
                return isSize;
                // }
            },
            // 图片上传成功后
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.addForm.banner_img = res.data.url
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
    .tab-box {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .banner-box {
        display: inline-block;
        /*width: 100%;*/
        width: 119.117647px;
        height: 0;
        /*padding-bottom: 67.1604938%;*/
        padding-bottom: 80px;
        position: relative;
        background: #f5f5f5;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    ::v-deep .img-uploader {
        width: 100%;
        height: 0;
        padding-bottom: 67.1604938%;
        background: #f5f5f5;
        position: relative;
        .el-upload {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .quickTalk-img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .add-box {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #999;
                .add-icon {
                    font-size: 30px;
                }
                .add-text {
                    font-size: 24px;
                }
            }
        }
    }
    ::v-deep .banner-list {
        width: 405px;
        height: 272px;
        background: #f5f5f5;
        margin: 0 auto;
        .el-carousel__container {
            height: 272px;
        }
        .banner-item {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
</style>